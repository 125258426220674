import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  MiniButton,
  RichTooltip,
  SnackbarBanner,
  TextLink,
  TooltipV2,
  TypographyComponent,
  useFeatureFlag,
} from "gx-npm-ui";
import { TeamManagementAppContext } from "../app.context";
import { TeamManagementTabs } from "../lib";
import TeamManagementNavBar from "./nav-bar/nav-bar.component";
import AddUserForm from "./team-management/add-user-form.component";
import TeamMembersDrawer from "./team-management/team-members-drawer.component";
import ScoringAssignments from "./scoring-assignments/scoring-assignments.component";
import styles from "./team-management.styles.module.scss";
import { LightBulbOffIcon } from "gx-npm-icons";
import { colorPalette } from "gx-npm-common-styles";
import { FreeTrialRole, getAsyncRequest, useUserState } from "gx-npm-lib";
import { GCOM_4327__EmailDomainCheckAddBack } from "../lib/feature-flags";

type ExportScorecardApiResponse = {
  status: number;
  data: {
    data: {
      signedUrl: string;
    };
  };
};
const TeamManagement = () => {
  const { t } = useTranslation();
  const refBulbIcon = useRef();
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const { isNotEmailDomainMatch, initiativeId, isLoading, selectedTab, teamMembers } =
    useContext(TeamManagementAppContext);
  const roleHelpLink = `https://intercom.help/gartner-buysmart-help-center/en/articles/6702650-user-roles-and-permissions`;
  const [shadow, setShadow] = useState(false);
  const [hasError, setHasError] = useState(false);
  const scrollContainer = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { freeTrialRole } = useUserState();
  const isTrialUser = !!freeTrialRole && Object.values(FreeTrialRole).includes(freeTrialRole);
  const ffGCOM4327ON = useFeatureFlag(GCOM_4327__EmailDomainCheckAddBack);

  useEffect(() => {
    const shadowOffset = 350;
    const scrollParent = scrollContainer.current;
    if (isLoading || !teamMembers.length) {
      return;
    }

    if (!scrollParent || !scrollRef.current) {
      return;
    }

    const toggleShadows = () => {
      const topLeftPos = scrollRef.current?.getBoundingClientRect()?.top || shadowOffset + 1;
      if (topLeftPos <= shadowOffset) {
        setShadow(true);
      } else if (topLeftPos > shadowOffset) {
        setShadow(false);
      }
    };

    scrollParent.addEventListener("scroll", toggleShadows);

    return () => {
      scrollParent.removeEventListener("scroll", toggleShadows);
    };
  }, [scrollContainer, teamMembers, isLoading]);

  const handleExportTeamData = async () => {
    try {
      const res: ExportScorecardApiResponse = await getAsyncRequest(
        `api/v3/initiatives/${initiativeId}/scorecards/export`
      );
      if (!(res.status === 200 && res.data?.data)) {
        throw new Error();
      }
      const { signedUrl } = res.data.data;
      window.open(signedUrl, "_self");
    } catch (err) {
      setHasError(true);
    }
  };

  return (
    <div>
      <TeamManagementNavBar />
      {selectedTab === TeamManagementTabs.TEAM_MANAGEMENT && (
        <div
          aria-label={t("team management")}
          className={classNames(styles.slideInBody, styles.slideInBodyTeamManagement)}
        >
          <section className={styles.titleContainer}>
            <div className={classNames(styles.exportTeamDiv)}>
              <TypographyComponent styling={"h3"} boldness={"medium"} rootClassName={styles.title}>
                {t("Team management")}
              </TypographyComponent>
              <span
                role="button"
                aria-label="bulb-icon-wrapper"
                tabIndex={0}
                ref={refBulbIcon as never}
                onClick={(event) => {
                  event.stopPropagation();
                  setTooltipOpen((prevState) => !prevState);
                }}
                onKeyDown={(event) => {
                  event.stopPropagation();
                  setTooltipOpen((prevState) => !prevState);
                }}
                className={styles.bulbIconWrapper}
              >
                <LightBulbOffIcon
                  fillColor={
                    isTooltipOpen ? colorPalette.interactions.selectedCta.hex : colorPalette.interactions.defaultCta.hex
                  }
                />
              </span>
              <RichTooltip
                theme={"dark"}
                content={[
                  {
                    type: "PARAGRAPH",
                    content: [
                      t("Build a more inclusive buying team. Consider adding 3+ collaborators to your evaluation."),
                    ],
                  },
                  {
                    type: "LIST_ITEMS",
                    content: [
                      t("Sponsor"),
                      t("Operations"),
                      t("Functional SME"),
                      t("Finance"),
                      t("Technical SME"),
                      t("Legal"),
                      t("Procurement/Vendor Management"),
                    ],
                  },
                ]}
                footerLinkText={t("Learn why")}
                footerLinkUrl={"https://www.gartner.com/document/4232099"}
                anchor={refBulbIcon.current}
                open={isTooltipOpen}
                onClose={() => setTooltipOpen(false)}
              />

              <div className={styles.exportTeamButton}>
                <TooltipV2
                  placement="top"
                  PopperProps={{ modifiers: { offset: { offset: "5, 5" } } }}
                  title={
                    isTrialUser
                      ? t("Exporting is not available in the Gartner BuySmart free trial.")
                      : t("This export includes team member information as well as scoring assignments and status.")
                  }
                >
                  <div>
                    <MiniButton disabled={isTrialUser} variant="default" onClick={handleExportTeamData}>
                      {t("Export team data")}
                    </MiniButton>
                  </div>
                </TooltipV2>
              </div>
              <SnackbarBanner isDefaultErrorMessage={true} isOpen={hasError} setIsOpen={setHasError} type="ERROR" />
            </div>

            <TypographyComponent styling={"p3"} rootClassName={classNames(styles.desc)} color={"iron"}>
              {t("Owners and Contributors will be granted access to scoring. Viewers will not have access to scoring.")}
            </TypographyComponent>
            <div className={styles.linkContainer}>
              <TextLink href={roleHelpLink} target="_blank" text={t("Learn about user roles")} variant={"secondary"} />
            </div>
            {ffGCOM4327ON && <div className={styles.separator} />}
          </section>
          <div className={styles.addUserFormWrapper}>
            <div data-testid="input-wrapper" className={classNames(shadow && "isScrolling")}>
              <AddUserForm />
            </div>
          </div>
          {!isNotEmailDomainMatch && (
            <div
              ref={scrollContainer}
              className={classNames(styles.bodyContainer, "gx-team-body-container", styles.bodyContainerTeamManagement)}
            >
              <div ref={scrollRef}>
                <TeamMembersDrawer />
              </div>
            </div>
          )}
        </div>
      )}
      {selectedTab === TeamManagementTabs.SCORING_ASSIGNMENTS && <ScoringAssignments />}
    </div>
  );
};
export default TeamManagement;
