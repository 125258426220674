// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.P6PkX68wcTpBeDT_39vr .K5omJUY_e42X9mov2JsB{padding-bottom:24px}.P6PkX68wcTpBeDT_39vr .FifNcZ0gO0XiWgOZeTgy{display:flex;padding-top:254px;position:absolute;right:56px;gap:34px}`, "",{"version":3,"sources":["webpack://./src/sections/team-management/add-user-form.styles.module.scss"],"names":[],"mappings":"AACE,4CACE,mBAAA,CAEF,4CACE,YAAA,CACA,iBAAA,CACA,iBAAA,CACA,UAAA,CACA,QAAA","sourcesContent":[".deleteContainer {\n  .deleteTitle {\n    padding-bottom: 24px;\n  }\n  .deleteFooter {\n    display: flex;\n    padding-top: 254px;\n    position: absolute;\n    right: 56px;\n    gap: 34px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deleteContainer": `P6PkX68wcTpBeDT_39vr`,
	"deleteTitle": `K5omJUY_e42X9mov2JsB`,
	"deleteFooter": `FifNcZ0gO0XiWgOZeTgy`
};
export default ___CSS_LOADER_EXPORT___;
